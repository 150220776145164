@import "~react-bnb-gallery/dist/style.css";

:root {
  --gray-color: rgba(0, 0, 0, 0.2);
  --light-gray-color: rgba(0, 0, 0, 0.05);
}

html {
  background: rgba(255, 255, 255);
  /* overflow-x: hidden; */
}

@media only screen and (max-width: 720px) {
  html {
    font-size: 14px;
  }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: "HelveticaNeue-Medium", "Helvetica Neue", Helvetica, Arial,
    sans-serif; */
  /* background: rgba(0, 0, 0, 0.02); */
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*[data-baseweb="popover"] {
  z-index: 1;
}

@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}

@keyframes scale {
  50% {
    transform: scale(0.9);
  }
}

.map-search-wrapper {
  height: 300px;
}

.mk-map-view {
  border-radius: 1rem;
  overflow: hidden;
}

.gallery-figcaption {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
}

button.thumbnail-button {
  border: none;
}

.gallery-control--prev > svg,
.gallery-control--next > svg {
  padding: 1rem;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  width: 1rem !important;
  height: 1rem !important;
}

.gallery-control--prev > svg:hover,
.gallery-control--next > svg:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 50%;
  margin: 1em auto;
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: 0.5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
}
.geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}
